@import "./common/variables";

.mainSlide{
  text-align: right;
  .slide{
    img{
      width: 100%;
    }
    .content{
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 1200px;
      color: #fff;
      span{
        font-size: 65px;
        font-family: $fontEL;
      }
      h2{
        font-size: 120px;
        font-family: $fontEB;
        margin-top: 10px;
        letter-spacing: 2px;
      }
    }
  }
  .pageingBall{
    position: absolute;
    text-align: center;
    bottom: 150px !important;
    z-index: 10;
    .swiper-pagination-bullet{
      width: 40px;
      height: 7px;
      border: 2px solid #fff;
      border-radius: 0;
      background: none;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
      background-color: #fff;
    }
  }
}

.brand{
  position: relative;
  height: 100vh;
  overflow: hidden;
  > img{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
  }
  .slideContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    h3{
      font-size: 35px;
      color: #fff;
      text-align: center;
      margin-bottom: 100px;
    }
    .brandSlide{
      padding: 0px 40px;
      background-color: #fff;
      .swiper-wrapper{
        align-items: center;
      }
      .swiper-wrapper:first-child{
        border-left: 2px solid #ddd;
      }
      .slide{
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 2px solid #ddd;
        a{
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          max-height: 60px;
          max-width: 100%;
        }
      }
    }
    .pageNav{
      width: 100%;
      i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        justify-content: center;
        color: #000;
        cursor: pointer;
        z-index: 10;
      }
      .prev{
        padding-left: 10px;
        left: 0px;
      }
      .next{
       right: 0px;
       padding-right: 10px;
      }
    }
    .content{
      text-align: center;
      margin-top: 200px;
      h2{
        color: #fff;
        font-size: 35px;
        font-family: $fontBold;
        br{
          display: none;
        }
      }
    }
  }
}

@media (max-width:1000px){
  .mainSlide{
    text-align: center;
  }
  .mainSlide .slide .content span{
    font-size: 45px;
  }
  .mainSlide .slide .content h2{
    font-size: 85px;
  }
  .mainSlide .slide .content{
    top: 38%;
    width: 100%;
  }
  .mainSlide .pageingBall{
    bottom: 10px !important;
  }
}

@media (max-width:600px){
  .mainSlide .slide .content span{
    font-size: 24px;
  }
  .mainSlide .slide .content h2{
    font-size: 24px;
    margin-top: 5px;
  }
  .brand .slideContainer h3{
    font-size: 26px;
    margin-bottom: 30px;
  }
  .brand .slideContainer .brandSlide .slide img{
    max-height: 40px;
  }
  .brand .slideContainer .content h2{
    font-size: 24px;
  }
  .brand .slideContainer .content h2 br{
    display: block;
  }
}

@media(max-width:500px){
  .mainSlide .slide img{
    min-width: 500px;
    min-height: 290px;
  }
  .mainSlide .slide .content{
    margin-top: 40px;
  }
}
@media(max-width:400px){
  .brand .slideContainer .content h2{
    font-size: 19px;
  }
}