.brandImage{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  li{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    height: 270px;
    a{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: relative;
      img{
        max-width: 206px;
      }
      p{
        position: absolute;
        bottom: 30px;
        font-size: 16px;
      }
    }
  }
}

@media(max-width:1200px){
  .brandImage li {
    padding: 10px;
  }
  .brandImage li img{
    width: 100%;
  }
  .brandImage li a p{
    bottom: 15px;
  }
}

@media(max-width:800px){
  .brandImage{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media(max-width:600px){
  .brandImage{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media(max-width:500px){
  .brandImage{
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width:330px){
  .brandImage{
    grid-template-columns: 1fr;
  }
}