.detailTitle{
  padding: 30px 0px;
  margin-bottom: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5{
    font-size: 35px;
    margin-bottom: 10px;
  }
  span{
    margin-right: 10px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    color: #555;
    i{
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .editButtons{
    i{
      font-size: 25px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.contents{
  border-bottom: 1px solid #ddd;
  padding-bottom: 100px;
  img{
    margin-bottom: 15px;
    max-width: 100%;
  }
  p{
    font-size: 16px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}