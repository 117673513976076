@import "./common/variables";

form.login{
  width: 250px;
  margin: 0 auto;
  padding: 100px 0px;
  h3{
    font-size: 18px;
    font-family: $fontBold;
    text-align: center;
    margin-bottom: 15px;
  }
  input{
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    line-height: 40px;
    padding: 0px 5px;
  }
  input[type="submit"]{
    background-color: #4da2e8;
    border: none;
    color: #fff;
    font-family: $fontBold;
  }
}