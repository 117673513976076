@import "../common/variables";

.bg{
  position: relative;
  > div:first-child{
    position: relative;
    min-height: 300px;
    overflow: hidden;
  }
  img{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
  .bgContent{
    position: absolute;
    top: -20px;
    background-color: #006fbd;
    color: #fff;
    padding: 20px;
    margin-left: 100px;
    p{
      font-family: $fontRegular;
      line-height: 1.2;
      &:first-child{
        margin-bottom: 50px;
      }
      &:last-child{
        font-size: 20px;
      }
    }
  }
}

.content{
  margin-top: 70px;
  h4{
    font-size: 30px;
    margin-bottom: 50px;
  }
  p{
    font-family: $fontRegular;
    font-size: 16px;
    line-height: 1.4;
    &:not(:last-child){
      margin-bottom: 30px;
    }
  }
}

.business{
  margin: 0 auto;
  display: block;
}

.loction{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  > li{
    width: 100%;
    .kakaoMap{
      width: 100%;
      height: 370px;
    }
    > h4{
      text-align: center;
      font-size: 24px;
      margin: 20px 0px;
      font-family: $fontBold;
    }
    > ul{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      > li{
        display: flex;
        width: 380px;
        align-items: center;
        text-align: left;
        margin: 0 auto;
        font-size: 16px;
        img{
          margin-right: 10px;
        }
      }
    }
  }
}

@media(max-width:1200px){
  .business{
    width: 100%;
  }
}

@media(max-width:1000px){
  .bg img{
    width: auto;
  }
}

@media(max-width:800px){
  .content p br{
    display: none;
  }
  .loction{
    grid-template-columns: 1fr;
  }
  .loction > li .kakaoMap{
    height: 250px;
  }
}

@media(max-width:600px){
  .bg{
    min-height: 250px;
  }
  .bg .bgContent{
    margin-left: 15px;
  }
  .content h4{
    font-size: 24px;
    margin-bottom: 30px;
  }
  .content p{
    font-size: 14px;
  }
}

@media(max-width:500px){
  .loction > li > ul > li{
    font-size: 14px;
    width: 288px;
  }
}